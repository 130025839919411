import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import Popup from 'reactjs-popup';

const Modal = () => (
<Popup
    trigger={<a className="strefa-klienta-button" >
    <Trans>Strefa Klienta</Trans>
  </a>}
    modal
    nested
  >
    {close => (
    
    <Container className="background-pattern">
        <Row className="modal popup-container">
            <Col className="popup-col" lg={6} md={6} sm={12} xs={12}>
                <h2 className="popup-header">Evodm24</h2>
                <p className="popup-text">dostęp do serwisu transakcyjno-informacyjnego gdzie sam dokonujesz transakcji związanymi z funduszami otwartymi.</p>
                <a href="https://evodm24.pl/client/login" className="popup-actions">Przejdź do serwisu</a>
            </Col>
            <Col className="popup-col" lg={6} md={6} sm={12} xs={12}>
                <h2 className="popup-header">Evoonline</h2>
                <p className="popup-text">dostęp do podglądu twoich inwestycji, ankiet oraz komentarzy rynkowych.</p>
                <a href="https://evoonline.pl/sign-in" className="popup-actions">Przejdź do serwisu</a>
            </Col>
            <button className="close" onClick={close}>x</button>
        </Row>
    </Container>
    )}
  </Popup>
  );


class LoginPopUp extends Component {
    render() {
      return (
        <Modal />
      );
    }
  }

  
export default withTranslation(["translations"])(LoginPopUp);